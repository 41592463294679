import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators
} from '@angular/forms';
import { UserService } from 'src/app/_core/services/user/user.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { REGEX } from '../../helpers/applicationConstants';

/**
 * TODO: Deprecado, reemplazar por nuevo componente de selector de cuits
 */
@Component({
  selector: 'app-add-cuit-modal',
  templateUrl: './add-cuit-modal.component.html',
  styleUrls: ['./add-cuit-modal.component.scss']
})
export class AddCuitModalComponent {
  /**
   * New CUIT.
   */
  public cuit = '';

  /**
   * CUIT to modify.
   */
  public cuitToModify = '';

  /**
   * Label of apply button.
   */
  public btnApplyLabel = '';

  /**
   * Array used for updating user's cuits.
   */
  public cuits: Array<{ cuit: string }> = [];

  /**
   * Used for error messages.
   */
  public showSuccessMessage = false;

  /**
   * Used for error messages.
   */
  public showErrorMessage = false;

  /**
   * Error message for back-end responses.
   */
  public errorMessage: string;

  /**
   * Success message for back-end responses.
   */
  public successMessage: string;

  /**
   * Add cuit form.
   */
  public addCuitForm: UntypedFormGroup;

  /**
   * Variable to store cuit's user.
   */
  public userId: any;

  /**
   * Show spinner during download process.
   */
  public showSpinner: boolean;

  /**
   * List of cuits for validation.
   */
  public cuitListState: any[];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public dialogRef: MatDialogRef<AddCuitModalComponent>,
    public userDataService: UserDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.btnApplyLabel = data.action;
    // User to add new cuit
    this.userId = data.userId;
    this.addCuitForm = this.formBuilder.group({
      cuit: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(REGEX.CUIT),
        Validators.minLength(13),
        Validators.maxLength(14)
      ]))
    });
    // CUIT to modify
    if (data.cuit) {
      this.addCuitForm.controls.cuit.setValue(data.cuit.cuit);
      this.cuitToModify = data.cuit.cuit;
    }

    // CUITS list for validation
    this.cuitListState = data.cuitListState;
  }

  /**
   * Determines whether the submit button is enabled or not based
   * on the login form status.
   * @returns true if the log in form is valid.
   */
  public buttonEnabled(): boolean {
    return this.addCuitForm.valid;
  }

  /**
   * Cleans Form after saving data.
   */
  public cleanForm() {
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
  }

  /**
   * Close dialog when user click cancel button
   */
  public closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Apply changes.
   */
  public async apply() {
    this.showSpinner = true;
    this.cuit = this.addCuitForm.controls.cuit.value;
    this.cuit = this.cuit.replace(/-/g, '');

    switch (this.btnApplyLabel) {
      case 'Guardar': {
        // Validates if CUIT is already assigned to user.
        if (this.userDataService.getUserCUITs().find((cuit) => cuit.cuit === this.cuit)) {
          this.errorMessage = 'El CUIT ingresado ya fue añadido al usuario.';
          this.showErrorMessage = true;
          this.showSpinner = false;
        } else {
          this.userService.addCuit(this.cuit).subscribe(
            (res: any) => {
              this.successMessage = (res.informarCuitNoActivo)
                ? 'El CUIT estará disponible para su uso desde mañana!'
                : 'Cuit agregado al usuario correctamente!';
              this.showSuccessMessage = true;
              this.addCuitForm.controls.cuit.setValue('');
              const result: any[] = res.cuits;
              result.forEach((value) => {
                this.cuits.push({ cuit: value });
              });
              this.userDataService.setUserCUITs(this.cuits, false);
              this.cuits = [];
              this.showSpinner = false;
            },
            (err: any) => {
              this.showSpinner = false;
            }
          );
        }
        break;
      } case 'Agregar': {
        // If editing a user, validates if CUIT is already assigned.
        let isUserCuit: boolean;

        if (this.userId) {
          const usersCuits: any = await this.userService.getUserCuits(this.userId).toPromise();

          isUserCuit = usersCuits?.cuits?.find((cuit) => cuit.cuit === this.cuit);
        } else {
          isUserCuit = false;
        }
        const wasDeleted = !this.cuitListState?.find(c => c.cuit === this.cuit);

        if (isUserCuit && wasDeleted) {
          this.dialogRef.close(isUserCuit);
          this.showSpinner = false;
          return;
        }

        // Validates if CUIT exists in DB
        this.userService.validateNewCuit(this.cuit, this.userId).subscribe(
          (res: any) => {
            const firstResult = res[0];
            this.dialogRef.close({
              cuit: this.cuit,
              razonSocial: firstResult?.razonSocial || '',
              cuitRole: firstResult?.tipoUsuario || '',
              conAcopioRelacionado: firstResult?.conAcopioRelacionado || '',
            });
            this.showSpinner = false;
          },
          (err: any) => {
            this.showSpinner = false;
          }
        );
        break;
      } case 'Modificar':
        // Validates if CUIT exists in DB
        this.userService.validateNewCuit(this.cuit, this.userId).subscribe(
          (res: any) => {
            this.dialogRef.close({ cuit: this.cuit, cuitToModify: this.cuitToModify, razonSocial: (res[0] || {}).razonSocial || '' });
            this.showSpinner = false;
          },
          (err: any) => {
            this.showSpinner = false;
          }
        );
        break;
      default:
        break;
    }
  }
}
